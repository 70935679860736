<template>
  <v-sheet class="pa-6">
    <v-toolbar flat>
      <v-toolbar-title>
        <h3>
          {{ $t('newReport.Report Hub') }}
        </h3>
      </v-toolbar-title>
      <v-divider
        class="mx-4"
        inset
        vertical />
      <v-spacer />
      <v-text-field
        v-model.trim="search"
        label="Search"
        color="secondary"
        hide-details />
    </v-toolbar>
    <div
      class="d-flex flex-wrap">
      <v-chip
        v-for="(tag, i) in categories"
        :key="`tag-${tag}-${i}`"
        class="mr-1 mt-1 cs-pointer"
        :outlined="!selectedCategories.includes(tag.text)"
        :text-color="!selectedCategories.includes(tag.text) ? '' : 'white'"
        :color="tag.color"
        small
        @click="selectCategory(tag.text)">
        {{ tag.text }}
      </v-chip>
    </div>
    <v-data-table
      class="tbl-height mt-2"
      :headers="headers"
      sort-by="index"
      :items="filterReports"
      dense
      :items-per-page="-1"
      hide-default-footer>
      <template #item.nameEn="{ item }">
        <RouterLink :to="{ name: item.to }">
          {{ item.nameEn }}
        </RouterLink>
      </template>
      <template #item.categories="{ item }">
        <v-chip
          v-for="(tag, i) in item.categories"
          :key="`tag-${tag}-${i}`"
          class="mr-1 cs-pointer"
          text-color="white"
          :color="categories.find(c => c.text === tag).color"
          small
          @click="selectCategory(tag)">
          {{ tag }}
        </v-chip>
      </template>
    </v-data-table>
  </v-sheet>
</template>

<script>
import { getRole } from '../../../assets/js/Authentication'
import reports from '../utils/reports.json'

export default {
  data () {
    return {
      reports: reports.map((r, index) => ({ ...r, index: index + 1 })),
      search: '',
      routes: [],
      headers: [
        {
          text: '#',
          value: 'index',
          align: 'center',
          width: 60
        },
        {
          text: 'Name',
          value: 'nameEn'
        },
        {
          text: 'Name TH',
          value: 'nameTh'
        },
        {
          text: 'Category',
          value: 'categories',
          sortable: false
        }
      ],
      selectedCategories: [],
      colors: [
        '#4caf50',
        '#2196f3',
        '#ff9800',
        '#f44336',
        '#9c27b0',
        '#673ab7',
        '#3f51b5',
        '#03a9f4',
        '#009688',
        '#ff5722',
        '#795548',
        '#607d8b',
        '#e91e63',
        '#1B5E20'
      ]
    }
  },
  computed: {
    filterReports () {
      const result = this.reports.filter(
        (report) => (
            report.nameEn.toLowerCase().includes(this.search.toLowerCase())
            || report.nameTh.toLowerCase().includes(this.search.toLowerCase())
            || report.description.toLowerCase().includes(this.search.toLowerCase())
            || report.searchDescription.toLowerCase().includes(this.search.toLowerCase())
          ) && this.checkMemberRole(report.to)
      )

      return result.filter((r) => this.selectedCategories.length === 0 || r.categories.some((c) => this.selectedCategories.includes(c)))
    },
    categories () {
      return [...new Set(this.reports.reduce((result, re) => [...result, ...re.categories], []))].map((c, i) => ({
        text: c,
        color: this.colors[i % this.colors.length]
      }))
    }
  },
  mounted () {
    this.routes = this.getRoutes(this.$router.options.routes)
  },
  methods: {
    selectCategory (cat) {
      if (this.selectedCategories.includes(cat)) {
        this.selectedCategories = this.selectedCategories.filter((c) => c !== cat)
      } else {
        this.selectedCategories.push(cat)
      }
    },
    checkMemberRole (routeName) {
      const role = getRole()
      const route = this.routes.find((r) => r.name === routeName)
      if (!route) return false
      if (route.meta && route.meta.middleware) {
        return route.meta.middleware.includes(role)
      }
      return true
    },
    getRoutes (routes) {
      const results = []
      routes.forEach((route) => {
        if (route.children) {
          results.push(...this.getRoutes(route.children))
        }
        results.push(route)
      })

      return results
    }
  }
}

</script>

<style scoped>
.tbl-height {
  height: calc(100vh - 80px - 64px - 48px - 64px);
  overflow-y: scroll;
}
.cs-pointer {
  cursor: pointer;
}
</style>
